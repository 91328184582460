import React from 'react';
import { Box, Text } from 'theme-ui';

/**
 * Contact Us - Updated on 13-01-2025 15:11:28
 */

const ContactInfo = () => (
  <Box sx={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
    <Text as="h1" sx={{ marginBottom: '20px', fontSize: '24px', fontWeight: 'bold' }}>
      Contact Us
    </Text>
    <Text as="p" sx={{ marginBottom: '10px' }}>
      <strong>Merchant Legal Entity Name:</strong> ANVESH REDDY THUMMALA
    </Text>
    <Text as="p" sx={{ marginBottom: '10px' }}>
      <strong>Registered Address:</strong> Flat no 504, Vaibhav Towers, Godavarikhani, Telangana, PIN: 505209
    </Text>
    <Text as="p" sx={{ marginBottom: '10px' }}>
      <strong>Operational Address:</strong> Flat no 504, Vaibhav Towers, Godavarikhani, Telangana, PIN: 505209
    </Text>
    <Text as="p" sx={{ marginBottom: '10px' }}>
      <strong>Telephone No:</strong> 9912287717
    </Text>
    <Text as="p" sx={{ marginBottom: '10px' }}>
      <strong>E-Mail ID:</strong> <a href="mailto:mail@anvesh.cloud">mail@anvesh.cloud</a>
    </Text>
  </Box>
);

export default ContactInfo;
