import React from 'react';
import { Layout, Stack, Main } from '@layout';
import PageTitle from '@components/PageTitle';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';

const CheckoutPage = props => {
  return (
    <Layout {...props}>
      <Seo title="DevOps Support Service" />
      <Divider />
      <Stack>
        <Main>
          <PageTitle header="DevOps Support Service" />
          <Divider />
          <section style={{ textAlign: 'center', margin: '20px 0' }}>
            <p style={{ fontSize: '1.1rem', color: '#555' }}>
              Thank you for choosing our DevOps support service. Please review the details below and proceed to payment to confirm your purchase.
            </p>
            <ul style={{ listStyle: 'none', padding: 0, fontSize: '1rem', color: '#333', lineHeight: '1.6' }}>
              <li><strong>Service:</strong> DevOps Support</li>
              <li><strong>Price:</strong> ₹5000</li>
              <li><strong>Description:</strong> Our comprehensive DevOps service is designed to streamline your development and operations processes, ensuring efficiency, scalability, and reliability. Below are the details of the services included:</li>
            </ul>
            <div style={{ textAlign: 'left', marginTop: '20px', fontSize: '0.9rem', color: '#555', lineHeight: '1.8' }}>
              <h3>Specific DevOps Tasks Covered</h3>
              <ul>
                <li>CI/CD Pipeline Implementation: Setting up pipelines using tools like GitHub Actions, Jenkins, or GitLab CI for automated build, test, and deployment.</li>
                <li>Infrastructure Automation: Provisioning and managing infrastructure using Terraform, AWS CloudFormation, or Ansible.</li>
                <li>Containerization: Dockerizing applications and managing container orchestration with Kubernetes or ECS.</li>
                <li>Monitoring and Logging: Integrating tools like Prometheus, Grafana, and ELK stack to ensure system health and performance tracking.</li>
                <li>Security Enhancements: Implementing DevSecOps practices, including automated security testing and vulnerability scanning.</li>
                <li>Cloud Management: Deployment and optimization of cloud resources on AWS, Azure, or GCP.</li>
              </ul>
              <h3>Tools Used</h3>
              <ul>
                <li>Version Control: Git, GitHub, Bitbucket</li>
                <li>CI/CD: GitHub Actions, Jenkins, GitLab CI/CD</li>
                <li>Infrastructure as Code (IaC): Terraform, AWS CloudFormation</li>
                <li>Containers: Docker, Kubernetes, Amazon ECS</li>
                <li>Monitoring: Prometheus, Grafana, CloudWatch</li>
                <li>Security: Snyk, Trivy, AWS IAM Policies</li>
              </ul>
              <h3>Support Duration</h3>
              <ul>
                <li><strong>Standard:</strong> 3-month support post-implementation for maintenance, updates, and troubleshooting.</li>
                <li><strong>Extended:</strong> Additional support packages are available upon request, billed hourly or as per negotiated terms.</li>
              </ul>
              <h3>Additional Costs</h3>
              <ul>
                <li>Infrastructure costs (cloud service charges) are billed separately.</li>
                <li>Extended support or custom feature requests will incur additional charges.</li>
                <li>Training sessions or documentation creation can be availed at an extra cost.</li>
              </ul>
              <h3>Service-Level Agreements (SLAs)</h3>
              <ul>
                <li><strong>Response Time:</strong> Initial response within 4 business hours for standard support.</li>
                <li><strong>Uptime Guarantee:</strong> Infrastructure deployed adheres to a 99.9% uptime target.</li>
                <li><strong>Resolution Time:</strong> Critical issues resolved within 24-48 hours; non-critical within 5 business days.</li>
              </ul>
            </div>
            <form style={{ marginTop: '30px' }}>
              <a href="https://payments.cashfree.com/forms/cloud443-devops" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <div className="button-container" style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#000',
                  color: '#fff',
                  padding: '15px 20px',
                  borderRadius: '30px',
                  boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2)',
                  transition: 'transform 0.3s ease',
                  cursor: 'pointer'
                }}
                onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.05)'}
                onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'} >
                  <img 
                    src="https://cashfree-checkoutcartimages-prod.cashfree.com/WhatsApp Image 2025-01-14 at 01.24.59_3399efa4W7uppp5dg0q0_prod.jpg" 
                    alt="logo" 
                    style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '15px' }} 
                  />
                  <div style={{ textAlign: 'left' }}>
                    <div style={{ fontSize: '1.2rem', fontWeight: '600' }}>Pay Now</div>
                    <div style={{ fontSize: '0.8rem', color: '#ccc', display: 'flex', alignItems: 'center' }}>
                      <span>Powered By Cashfree</span>
                      <img 
                        src="https://cashfreelogo.cashfree.com/cashfreepayments/logosvgs/Group_4355.svg" 
                        alt="Cashfree logo" 
                        style={{ width: '20px', height: '20px', marginLeft: '8px' }} 
                      />
                    </div>
                  </div>
                </div>
              </a>
            </form>
          </section>
        </Main>
      </Stack>
    </Layout>
  );
};

export default CheckoutPage;
