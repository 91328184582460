import React from 'react';
import { Layout, Stack, Main } from '@layout';
import PageTitle from '@components/PageTitle';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';

const PageCancellationRefundPolicy = props => (
  <Layout {...props}>
    <Seo title="Cancellation & Refund Policy" />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="Cancellation & Refund Policy" />
        <Divider />
        <section>
          <p><strong>Last updated on 13-01-2025 15:13:54</strong></p>
          <p>
            ANVESH REDDY THUMMALA believes in helping its customers as far as possible, and has 
            therefore a liberal cancellation policy. Under this policy:
          </p>
          <ul>
            <li>
              Cancellations will be considered only if the request is made immediately after placing the order. 
              However, the cancellation request may not be entertained if the orders have been communicated 
              to the vendors/merchants and they have initiated the process of shipping them.
            </li>
            <li>
              ANVESH REDDY THUMMALA does not accept cancellation requests for perishable items like 
              flowers, eatables, etc. However, refund/replacement can be made if the customer establishes that 
              the quality of the product delivered is not good.
            </li>
            <li>
              In case of receipt of damaged or defective items, please report the same to our Customer Service 
              team. The request will, however, be entertained once the merchant has checked and determined the 
              same at their own end. This should be reported within <strong>only the same day</strong> of receipt of the 
              products. In case you feel that the product received is not as shown on the site or as per your 
              expectations, you must bring it to the notice of our customer service within <strong>only the same day</strong> 
              of receiving the product. The Customer Service Team, after looking into your complaint, will 
              take an appropriate decision.
            </li>
            <li>
              In case of complaints regarding products that come with a warranty from manufacturers, please refer 
              the issue to them. In case of any refunds approved by ANVESH REDDY THUMMALA, it’ll take 
              <strong>12 days</strong> for the refund to be processed to the end customer.
            </li>
          </ul>
        </section>
      </Main>
    </Stack>
  </Layout>
);

export default PageCancellationRefundPolicy;
